import React from "react"
import { useLocation, Redirect } from "react-router"
import { Helmet } from "react-helmet-async"
import { BlockResolver, ViewLoader } from ".."
import { Blocks } from "../../@types/graphql.generated"
import { useBlockQueryParams } from "../../hooks"
import useSSR from "use-ssr"
import { usePageQueryTracking, useUserLoginTracking, useExternalScriptsLoading } from "./utils"
import { Flex } from "@focus-nordic/ui-components"
import { MAIN_MIN_HEIGHT } from "../../constants"
import {
	GetPageQueryResult,
	useGetPageQuery
} from "../../operations/page.generated"
interface BlocksPageContextValue {
	pageQueryResult?: GetPageQueryResult
}

export const BlocksPageContext = React.createContext<BlocksPageContextValue>({})

const BlocksPage: React.FC = () => {
	const location = useLocation()

	const { isServer } = useSSR()
	let productListBlockQueryParams = useBlockQueryParams()

	const pageQueryResult = useGetPageQuery({
		variables: {
			path: decodeURI(`${location.pathname}${location.search}`),
			...productListBlockQueryParams
		}
	})
	
	useExternalScriptsLoading(pageQueryResult)
	usePageQueryTracking(pageQueryResult)
	useUserLoginTracking()

	return (
		<Flex
			flex={1}
			flexDirection="column"
			alignItems="center"
			w={1}
			minh={MAIN_MIN_HEIGHT}
		>
			{/* use custom loading logic (isLoading) if the host enviroment is a browser */}
			{!isServer && pageQueryResult.loading ? (
				<ViewLoader />
			) : (
				<>
					{/* handle 301 redirect */}
					{pageQueryResult?.data?.page?.redirectUrl && (
						<Redirect to={pageQueryResult.data.page.redirectUrl} />
					)}
					<React.Fragment key={`${location.pathname}-${pageQueryResult.data?.page?.id}`}>
						<Helmet>
							{pageQueryResult.data?.page?.meta?.title && (
								<title>{pageQueryResult.data?.page?.meta?.title}</title>
							)}
							{pageQueryResult.data?.page?.meta?.description && (
								<meta
									name="description"
									content={pageQueryResult.data?.page?.meta?.description}
								/>
							)}
							<meta
								name="viewport"
								content="width=device-width, initial-scale=1.0"
							/>
							{pageQueryResult.data?.page?.canonicalUrl && (
								<link
									rel="canonical"
									href={pageQueryResult.data.page.canonicalUrl}
								/>
							)}
						</Helmet>
						<BlocksPageContext.Provider value={{ pageQueryResult }}>
							{pageQueryResult.data?.page?.blocks && (
								<BlockResolver
									blocks={pageQueryResult.data?.page?.blocks as Array<Blocks>}
								/>
							)}
						</BlocksPageContext.Provider>
					</React.Fragment>
				</>
			)}
		</Flex>
	)
}

export { BlocksPage }
